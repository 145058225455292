.header{
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
}
.logo{
  width: 10rem;
  height: 3rem;

}
.header-manue{
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.header-manue>li:hover{
  cursor: pointer;
  color: var(--orange);
}

@media screen and (max-width: 768px){
  .header > :nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-manue{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}